import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.scss';
import apiService from "../../services/apiService";

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const data = await apiService.login(username, password);
            localStorage.setItem('authToken', data.token);
            navigate('/');
        } catch (err) {
            setError('Nom d’utilisateur ou mot de passe incorrect');
            console.error('Erreur lors de la connexion:', err);
        }
    };

    return (
        <div className="page login">
            <div className="container login-wrapper">
                <h1><span>Bonjour</span><br/>la famille !</h1>
                {error && <div className="error"><p className="text-green text-center">{error}</p></div>}
                <form onSubmit={handleLogin}>
                    <div className="input-group">
                        <input
                            type="email"
                            placeholder="Email"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            autoComplete="email"
                        />
                        <label>Email</label>
                    </div>

                    <div className="input-group">
                        <input
                            type="password"
                            placeholder="Mot de passe"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete={"current-password"}
                        />
                        <label>Mot de passe</label>
                    </div>

                    <div className="text-center">
                        <button type="submit" className="btn">Se connecter</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
