import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('authToken'); // Récupère le token du localStorage

    if (!token) {
        // Si le token n'est pas trouvé, redirige vers la page de connexion
        return <Navigate to="/connexion" replace />;
    }

    // Si le token est présent, l'utilisateur est authentifié, on rend la route accessible
    return children;
};

export default ProtectedRoute;
