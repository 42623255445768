import React, { useEffect, useRef, useState } from 'react';
import './Playlist.scss';
import iconPlay from "../../img/icon-play.svg";
import iconPause from "../../img/icon-pause.svg";
import iconNext from "../../img/icon-next.svg";
import H5AudioPlayer from "react-h5-audio-player";
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from "@gsap/react";

function Playlist({ userSpace }) {

    gsap.registerPlugin(useGSAP);
    gsap.registerPlugin(ScrollTrigger);

    const player = useRef(null);

    useGSAP(() => {
        if (player.current) {
            gsap.to(player.current, {
                scrollTrigger: {
                    trigger: player.current,
                    start: "top top",
                    end: "100000vh",
                    scrub: true,
                    pin: true,
                    pinSpacing: false
                }
            });
        }
    }, {});

    const [selectedItemIndex, setSelectedItemIndex] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const [autoPlayOnSrcChange, setAutoPlayOnSrcChange] = useState(false);
    const [durations, setDurations] = useState([]);  // État pour stocker les durées des pistes

    // Formatage de la date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('fr-FR', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        }).format(date);
    };

    // Récupérer la durée de chaque piste audio
    const loadDurations = () => {
        if (userSpace && userSpace.acf && userSpace.acf.documents) {
            const promises = userSpace.acf.documents.map(item => {
                return new Promise(resolve => {
                    const audio = new Audio(item.acf.audio.url);
                    audio.addEventListener('loadedmetadata', () => {
                        resolve(audio.duration); // Récupérer la durée de la piste
                    });
                });
            });

            // Résoudre toutes les promesses et mettre à jour l'état des durées
            Promise.all(promises).then(durations => {
                setDurations(durations);
            });
        }
    };

    useEffect(() => {
        if (userSpace && userSpace.acf && userSpace.acf.documents && userSpace.acf.documents.length > 0) {
            if (selectedItem === null) {
                setSelectedItem(userSpace.acf.documents[0]);
                setAutoPlayOnSrcChange(false);
            }
            loadDurations();  // Charger les durées des pistes audio
        }
    }, [userSpace]);

    const handleClick = (item, index) => {
        setSelectedItem(item);
        setSelectedItemIndex(index);
        setAutoPlayOnSrcChange(true);
    };

    const handleNextClick = () => {
        const nextIndex = selectedItemIndex + 1;
        if (userSpace.acf.documents[nextIndex]) {
            setSelectedItem(userSpace.acf.documents[nextIndex]);
            setSelectedItemIndex(nextIndex);
            setAutoPlayOnSrcChange(true);
        }
    };

    // Convertir la durée en format minutes:secondes
    const formatDuration = (duration) => {
        const minutes = Math.floor(duration / 60);
        const seconds = Math.floor(duration % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div className="page playlist">
            <div className="playlist-title bg-yellow">
                <div className="container">
                    <h1>Playlist</h1>
                    <p>Nobit faces intem es doloriandi autatur re evero estibearum cum faciis exereptatium quaepuda quis dit quam ratque volore nosam verum et odit a ne quiam.</p>
                </div>
            </div>
            <div className="playlist-player bg-yellow-50" ref={player}>
                <div className="container">
                    <div className="playlist-player-wrapper">
                        <h5>#{selectedItemIndex + 1} - {selectedItem ? formatDate(selectedItem.post_date) : ''} - {selectedItem ? selectedItem.acf.expediteur.acf.prenom_nom : ''} à {selectedItem ? selectedItem.acf.destinataire.acf.prenom_nom : ''}</h5>
                        <H5AudioPlayer
                            src={selectedItem ? selectedItem.acf.audio.url : ''}
                            onEnded={handleNextClick}
                            customAdditionalControls={[]}   // Enlever les boutons supplémentaires comme le volume
                            showJumpControls={false}        // Enlever les boutons de saut
                            layout="horizontal-reverse"     // Inverser l'ordre pour personnaliser
                            customProgressBarSection={[]}
                            customControlsSection={["MAIN_CONTROLS", "PROGRESS_BAR", "CURRENT_TIME", "DURATION"]}
                            customIcons={{
                                play: <img src={iconPlay} alt="Play"/>,    // Icône personnalisée pour Play
                                pause: <img src={iconPause} alt="Pause"/>   // Icône personnalisée pour Pause
                            }}
                            autoPlay={false}
                            autoPlayAfterSrcChange={autoPlayOnSrcChange}
                        />
                        <div className="action-next">
                            <button className="btn-link" onClick={handleNextClick}>
                                <img src={iconNext} alt="Next"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="playlist-tracks">
                {userSpace ? userSpace.acf.documents.map((item, index) => (
                    <div key={item.ID} onClick={() => handleClick(item, index)} className={`playlist-track ${selectedItem && selectedItem.ID === item.ID ? 'active' : ''}`}>
                        <div className="container playlist-track-wrapper">
                            <div className="number">
                                <h5>#{index + 1}</h5>
                            </div>
                            <div className="from">
                                <div className={`timeline-item timeline-item-small ${item.acf.expediteur.acf.genre} ${selectedItem && selectedItem.ID === item.ID ? 'timeline-item-selected' : ''}`}>
                                    <div className="timeline-item-img">
                                        <img src={item.acf.expediteur.acf.profil} alt={`Profile of ${item.acf.expediteur.acf.prenom_nom}`}/>
                                    </div>
                                </div>
                                <div>
                                    <h5 className="from-date"><strong>{formatDate(item.post_date)}</strong></h5>
                                    <h5>{item.acf.expediteur.acf.prenom_nom}</h5>
                                </div>
                            </div>

                            <div className="time">
                                {/* Affichage de la durée */}
                                <h5>{durations[index] ? formatDuration(durations[index]) : '...'}</h5>
                            </div>
                        </div>
                    </div>
                )) : ''}
            </div>
        </div>
    );
}

export default Playlist;
