import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

const PageTransition = ({ children }) => {
    const location = useLocation();
    const [previousPath, setPreviousPath] = useState(location.pathname);  // Utilisation de l'état pour stocker le chemin précédent
    const [direction, setDirection] = useState(0);  // Utilisation de l'état pour stocker la direction

    const pages = {
        "/": 1,           // Home
        "/lettres": 2,    // Lettres
        "/playlist": 3    // Playlist
    };

    useEffect(() => {
        // Comparer la page actuelle avec la précédente pour définir la direction
        if (pages[location.pathname] < pages[previousPath]) {
            setDirection(-100);  // Slide from left to right
        } else if (pages[location.pathname] > pages[previousPath]) {
            setDirection(100);   // Slide from right to left
        }
        setPreviousPath(location.pathname);
    }, [location.pathname, previousPath, pages]);  // Met à jour chaque fois que la page change

    return (
        <motion.div
            key={location.pathname}  // Utilisation du pathname comme clé pour l'animation
            initial={{ x: direction }}  // Départ avec une position initiale et opacité
            animate={{ x: 0 }}  // Animation jusqu'au centre avec opacité complète
            exit={{ x: direction }}    // Sortie avec direction opposée
            transition={{ duration: 0.5 }}  // Durée de la transition
            style={{ display: "flex", flex: 1 }}
        >
            {children}
        </motion.div>
    );
};

export default PageTransition;
