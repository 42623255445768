// src/pages/Lettres.js
import React, {useEffect, useRef, useState} from 'react';
import iconFrom from "../../img/icon-from.svg";
import iconTo from "../../img/icon-to.svg";
import iconPlay from "../../img/icon-play.svg";
import iconPause from "../../img/icon-pause.svg";
import iconVoir from "../../img/icon-voir.svg";
import H5AudioPlayer from "react-h5-audio-player";
import './Lettres.scss';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import {useGSAP} from "@gsap/react";

function Lettres({ userSpace }) {

    gsap.registerPlugin(useGSAP);
    gsap.registerPlugin(ScrollTrigger);

    const lettreInfo = useRef(null);

    useGSAP(() => {
            if (lettreInfo.current) {
                gsap.to(lettreInfo.current, {
                    scrollTrigger: {
                        trigger: lettreInfo.current,
                        start: "top top",
                        end: "100000vh",
                        scrub: 1,
                        pin: true,
                        pinSpacing: false
                    }
                });
            }
        }, [ lettreInfo, ]
    );

    const [selectedItemIndex, setSelectedItemIndex] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        if (userSpace && userSpace.acf && userSpace.acf.documents && userSpace.acf.documents.length > 0) {
            if (selectedItem === null) {
                setSelectedItem(userSpace.acf.documents[0]);
            }
        }
    }, [selectedItem, userSpace]);

    const handleClick = (item, index) => {
        setSelectedItem(item);
        setSelectedItemIndex(index);
    };

    const handleNextClick = () => {
        const nextIndex = selectedItemIndex + 1
        if (userSpace.acf.documents[nextIndex]) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setTimeout(() => {
                setSelectedItem(userSpace.acf.documents[nextIndex]);
                setSelectedItemIndex(nextIndex);
            }, 500);
        }
    };

    const formatDate = (dateString) => {
        // Convertir la chaîne en objet Date
        const date = new Date(dateString);

        // Utiliser Intl.DateTimeFormat pour formater la date
        return new Intl.DateTimeFormat('fr-FR', {
            weekday: 'long', // nom complet du jour (ex: jeudi)
            year: 'numeric', // année sur 4 chiffres (ex: 1948)
            month: 'long',   // nom complet du mois (ex: décembre)
            day: 'numeric'   // numéro du jour (ex: 3)
        }).format(date);
    }

    return (
        <div className="page lettres">
            <div className="timeline">
                <div className="timeline-line"></div>
                <div className="timeline-wrapper">
                    {userSpace ? userSpace.acf.documents.map((item, index) => (
                        <div
                            className={`timeline-item ${item.acf.expediteur.acf.genre} ${selectedItem && selectedItem.ID === item.ID ? 'timeline-item-selected' : ''}`}
                            key={item.ID} onClick={() => handleClick(item, index)}>
                            <div className="timeline-item-img">
                                <img src={item.acf.expediteur.acf.profil}/>
                            </div>
                            <h5>{item.title}</h5>
                        </div>
                    )) : ''}
                </div>
            </div>
            <div className={`from-to ${selectedItem && selectedItem.acf.expediteur.acf.genre === 'woman' ? 'bg-pink-50' : 'bg-blue-50'}`}>
                <div className="container">
                    <div className="from-to-wrapper">
                        <div className="from">
                            <h5>{selectedItem ? selectedItem.acf.expediteur.acf.prenom_nom : ''}</h5>
                        </div>
                        <div className="transit">
                            <img src={iconFrom} alt="" className="imgFrom"/>
                            <div className="transit bg-black"></div>
                            <img src={iconTo} alt="" className="imgTo"/>
                        </div>
                        <div className="from">
                            <h5>{selectedItem ? selectedItem.acf.destinataire.acf.prenom_nom : ''}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div id="lettre-info" className={`${selectedItem && selectedItem.acf.expediteur.acf.genre === 'woman' ? 'bg-pink' : 'bg-blue'}`} ref={lettreInfo}>
                <div className="container">
                    <div className="lettre-info">
                        <div className={`timeline-item timeline-item-big timeline-item-selected`}>
                            <div className="timeline-item-img">
                                <img src={selectedItem ? selectedItem.acf.expediteur.acf.profil : ''} alt={`Profile of ${selectedItem ? selectedItem.acf.expediteur.acf.title : ''}`}/>
                            </div>
                        </div>
                        <div className="lettre-info-title">
                            <h4>{selectedItem ? formatDate(selectedItem.post_date) : ''}</h4>
                            <h1>{selectedItem ? selectedItem.post_title : ''}</h1>
                        </div>
                    </div>
                    <div className="lettre-action">
                        <div className="lettre-action-player">
                            <H5AudioPlayer
                                src={selectedItem ? selectedItem.acf.audio.url : ''}
                                onPlay={e => console.log("Playing")}
                                onEnded={e => console.log("Audio ended")}
                                customAdditionalControls={[]}   // Enlever les boutons supplémentaires comme le volume
                                showJumpControls={false}        // Enlever les boutons de saut
                                layout="horizontal-reverse"     // Inverser l'ordre pour personnaliser
                                customProgressBarSection={[]}
                                customControlsSection={["MAIN_CONTROLS", "PROGRESS_BAR", "CURRENT_TIME", "DURATION"]}

                                customIcons={{
                                    play: <img src={iconPlay}/>,    // Icône personnalisée pour Play
                                    pause: <img src={iconPause}/>   // Icône personnalisée pour Pause
                                }}
                                autoPlay={false}
                                autoPlayAfterSrcChange={false}
                            />
                        </div>
                        <div className="lettre-action-viewer">
                            <button className="btn-link">
                                <img src={iconVoir} alt=""/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container lettre-contenu" dangerouslySetInnerHTML={{__html: selectedItem ? selectedItem.acf.transcript : ''}}></div>
            <div className="lettre-suivante text-center">
                <button className="btn" onClick={() => handleNextClick()}>
                    Lettre suivante
                </button>
            </div>
        </div>
    );
}

export default Lettres;
