import React from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import iconHome from '../../img/icon-home.svg';
import iconPlay from '../../img/icon-play.svg';
import iconLettre from '../../img/icon-lettre.svg';
import './Header.scss';

function Header() {

    const location = useLocation();
    const isActive = (path) => location.pathname === path;

    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        navigate('/connexion');
    }

    return (
        <header className={`header ${location.pathname === '/connexion' ? 'hide' : ''}`}>
            <div className="container header-nav">
                <button className="btn-link me-auto" onClick={handleLogout}>
                    <h5>Se déconnecter</h5>
                </button>
                <Link to="/" className={`btn-link home ms-auto`}>
                    <img src={iconHome} alt="Home" className={`${isActive("/") ? "stroke-green" : ""}`}/>
                </Link>
                <Link to="/lettres" className={`btn-link lettres ms-auto`}>
                    <img src={iconLettre} alt="Lettres" className={`${isActive("/lettres") ? "active" : ""}`}/>
                </Link>
                <Link to="/playlist" className={`btn-link playlist ms-auto`}>
                    <img src={iconPlay} alt="Playlist" className={`${isActive("/playlist") ? "active" : ""}`}/>
                </Link>
            </div>

        </header>
    );
}

export default Header;
