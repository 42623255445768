// src/pages/Home.js
import React, {useRef} from 'react';
import './Home.scss';
import {Link} from "react-router-dom";
import gsap from "gsap";
import {useGSAP} from "@gsap/react";
import {ScrollTrigger} from "gsap/ScrollTrigger";

function Home({ userSpace }) {

    gsap.registerPlugin(useGSAP);
    gsap.registerPlugin(ScrollTrigger);

    const bonjourTitle = useRef();

    useGSAP(() => {
        if (bonjourTitle.current) {
            gsap.to(bonjourTitle.current, {
                scrollTrigger: {
                    trigger: bonjourTitle.current,
                    start: "top top",
                    end: "100000vh",
                    scrub: true,
                    pin: true,
                    pinSpacing: false
                }
            });
        }
    });

    return (
        <div className="page home bg-green">
            <div className="container home-wrapper">
                <div ref={bonjourTitle} className="bg-green bonjour-title">
                    <div>
                        <h1>Bonjour</h1>
                        <h2 className="text-white">{userSpace ? userSpace.acf.titre : ''}</h2>
                    </div>
                </div>
                <p dangerouslySetInnerHTML={{__html: userSpace ? userSpace.acf.description : ''}}></p>
                <div className="text-center">
                    <Link to="/lettres">
                        <button className="btn ms-auto me-auto">
                            Commencer
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Home;
